import { Component } from '@angular/core';
import { PageService } from 'src/app/services/page.service';
interface SideNavToggle{
  screenWidth: number;
  collapsed: boolean;
}
@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class cFullComponent {

  constructor(private pageService: PageService) { 
    this.pageService.setCurrentPage(1);
  }
  isSideNavCollapsed = true;
  screenWidth: number = 0;

  onToggleSidenav(data: SideNavToggle):void
  {
    this.screenWidth = data.screenWidth;
    this.isSideNavCollapsed = data.collapsed;
  }
 }
