<div class="sidenav" [ngClass]="collapsed ? 'sidenav-collapsed':''">
    <ul class="sidenav-nav" *ngIf="collapsed">
        <li class="sidenav-nav-item" *ngFor="let data of navData" (click)="setPage()">
            <a href="javascript:void(0)" class="sidenav-nav-link"  (click)="handleClick(data)"
            *ngIf="data.items && data.items.length>0"
           >
                <i class="sidenav-link-icon sidebar-icons" *ngIf="collapsed" [class]="data.icon"></i>
                <span class="sidenav-link-text" @faadeInOut *ngIf="collapsed">{{data.label}}</span>

                <i *ngIf="data.items && collapsed" class="menu-collapse-icon fa-solid"
                [ngClass]="!data.expanded ? 'fa-angle-right':'fa-angle-down'"
                ></i>
            </a>
            <a *ngIf="!data.items || (data.items && data.items.length === 0)"
            href="javascript:void(0)" class="sidenav-nav-link" [routerLink]="data.routerLink" 
            
            >
            <span class="sidenav-link-icon sidebar-icons" [class]="data.icon"></span>
                <span class="sidenav-link-text"  routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}" @faadeInOut *ngIf="collapsed">{{data.label}}</span>
            </a>

            <div *ngIf="collapsed && data.items && data.items.length > 0">
                <app-sublevel-menu
                [data]="data"
                [collapsed]="collapsed"
                [multiple]="multiple"
                [expanded]="data.expanded"
                >
                </app-sublevel-menu>
            </div>
        </li>
    </ul>
</div>