import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { INavbarData } from '../helper';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PageService } from 'src/app/services/page.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-sublevel-menu',
  templateUrl: './sublevel-menu.component.html',
  styleUrls: ['./sublevel-menu.component.scss'],
  animations: [
    trigger('submenu',[
      state('hidden', style({
        height:'0',
        overflow: 'hidden'
      })),
      state('visible', style({
        height: '*'
      })),
      transition('visible <=> hidden',[style({overflow:'hidden'}),
    animate('{{transitionParams}}')]),
    transition('void => *',animate(0))
    ])
  ]
})
export class SublevelMenuComponent  {
  curLink: string;
  constructor(private pageService:PageService,private route:Router){
    this.curLink=this.route.url
  }

  @Input() data : INavbarData = {
    routerLink:'',
    icon:'',
    label:'',
    items:[]
  }

  @Input() collapsed = false;
  @Input() animating: boolean | undefined;
  @Input() expanded: boolean | undefined ;
  @Input() multiple: boolean =false ;


  handleClick(item:any)
  {
    if(!this.multiple){
      if(this.data.items && this.data.items.length > 0){
        for(let modelItem of this.data.items){
          if(item !== modelItem && modelItem.expanded){
            modelItem.expanded = false;
          }
        }
      }
    }
    item.expanded = !item.expanded;
  }
  setPage(){
    this.pageService.setCurrentPage(1);
  }
}
