import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { INavbarData } from './helper';
import { navbarData } from './nav-data';
import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { PageService } from 'src/app/services/page.service';
import { Router } from '@angular/router';

interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations:[
    trigger('faadeInOut',[
      transition(':enter',[
        style({opacity:0}),
        animate('350ms',
        style({opacity:1})
        )
      ]),
      transition(':leave',[
        style({opacity:1}),
        animate('350ms',
        style({opacity:0}))
      ])
    ]),
    trigger('rotate',[
      transition(':enter',[
        animate('1000ms',
        keyframes([
          style({transform: 'rotate(0deg)' , offset:'0'}),
          style({transform: 'rotate(2turn)' , offset:'1'})
        ]))
      ])
    ])
  ],
})
export class SidebarComponent {
@Input() collapsed:any;
@Output() onToggleSideNav: EventEmitter<SideNavToggle> = new EventEmitter();
// collapsed: boolean = true;
screenWidth: number =0;
navData = navbarData;
multiple : boolean = false;
expandedList: any = [];
constructor(private pageService:PageService,private route:Router){}


  handleClick(item: INavbarData)
  {
    if(!this.multiple) {
      for(let modelItem of this.navData){
        
        if(item !== modelItem && modelItem.expanded){
          modelItem.expanded = false;
          this.expandedList.push(item)
        }
      }
    }
    item.expanded = !item.expanded;
    
  }
  setPage(){
    this.pageService.setCurrentPage(1);
  }
}
