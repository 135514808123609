// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
 production: window["env"]["production"] || false,
 APP_NAME: window["env"]["APP_NAME"] || 'KyndrylALM',
 Title: window["env"]["Title"] || 'Kyndryl ALM',
 BaseURL: window["env"]["BaseURL"] || 'https://wb-kalm-demo-dev.trilloapps.com',
 AccessToken : window["env"]["AccessToken"] || 'lskyndrylIlmAccessToken',
 start: window["env"]["start"] || 1,
 size: window["env"]["size"] || 20,
 mapApiKey : window["env"]["mapApiKey"] || 'AIzaSyA5-s6IAoOOHAN1WDT65voTKDY5KmZmBPE',
 firebase: {
   apiKey: window["env"]["apiKey"] || 'AIzaSyA68Bgb0WvsdxsWyIXCJZiWt9Uy7DhcpKQ',
   authDomain: window["env"]["authDomain"] || 'kalm-demo-dev.firebaseapp.com',
   projectId: window["env"]["projectId"] || 'kalm-demo-dev',
   storageBucket: window["env"]["storageBucket"] || 'kalm-demo-dev.appspot.com',
   messagingSenderId: window["env"]["messagingSenderId"] || '592936075341',
   appId: window["env"]["appId"] || '1:592936075341:web:95d6db1843beab54991c04',
   vapidKey: window["env"]["vapidKey"] || 'BIy2lQ0eCV8qnRuG638iPplgShv7xmyeTj0xzaPljrgPyAkFvrykc1906ioN-RFbdERf0OfrZt9rItb1v8lQkmw'
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
