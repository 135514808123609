import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'video-feed',
  templateUrl: './video-feed.component.html',
  styleUrls: ['./video-feed.component.css']
})
export class VideoFeedComponent implements OnInit {
  videos: any[] = [];
  expandedClass: number = 0;
  vehicleId: any;
  loader : boolean = false;
  constructor(private route : ActivatedRoute, private dataService : DataService){}
  ngOnInit()
  {
    this.route.queryParams.subscribe(params => {
      this.vehicleId = params['vehicleId'];// Output: 1
    });
    if(this.vehicleId) this.getVideoFootages(this.vehicleId)
  }


  getVideoFootages(vehicleId: number)
  {
    let body = {
      vehicleId: vehicleId
    }
    this.loader = true
    this.dataService.GetVideoFootages(body).subscribe({
      next: (result)=>{
        this.loader = false
        this.videos = result.data
        console.log("Response:",this.videos);
        
      },
      error: (err) =>{},
      complete: () =>{}
    })
  }
  toggleExpand(box): void {
    box==this.expandedClass?this.expandedClass=0:this.expandedClass = box
  }
  
  
}
