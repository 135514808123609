import { Component, OnDestroy, OnInit } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Router } from '@angular/router';
import { Keepalive } from '@ng-idle/keepalive';
import { SessionService } from './services/session.service';
import { environment } from 'src/environments/environment';
import { BrowserCompatibilityService } from './services/browser-compatibility.service';
import { DataService } from './services/data.service';
import * as notes from '../assets/ReleaseNotes.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit , OnDestroy{
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'app';
  bOldBrowser: boolean;
  oBroswerNameVersion: { browserName: string; browserVersion: number; };
  lReleaseNotes : {} = notes;
  constructor( private dataService:DataService ,private oCheckIdleState: Idle, private keepalive: Keepalive, private oRouter: Router, private oSessionService: SessionService,private oBroswerVersionService: BrowserCompatibilityService) {
    console.warn("Build Version ===> ",this.lReleaseNotes[0]?.ReleaseVersion);
    
    this.bOldBrowser = this.oBroswerVersionService.isOldBrowser();
    this.oBroswerNameVersion =this.oBroswerVersionService.GetBrowserNameAndVersion();
    // sets an idle timeout of 5 seconds, for testing purposes.
    oCheckIdleState.setIdle(5);
    // sets a timeout period of 30 minutes. After 30 minutes and 5 seconds of inactivity, the user will be considered timed out.
    oCheckIdleState.setTimeout(1800);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    oCheckIdleState.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    oCheckIdleState.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log("On idle end Idle state : ==> ", this.idleState);
    });
    oCheckIdleState.onTimeout.subscribe(() => {
      console.log("heretimeout");
      
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log("On Time out Idle state : ==> ", this.idleState);
      this.AppComponent_ResetTimer();
    });
    oCheckIdleState.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      console.log("On idle start Idle state : ==> ", this.idleState);
    });
    oCheckIdleState.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      // console.log("on Timeout warning Idle state : ==> ", this.idleState);
    });

    // sets the ping interval to 5 seconds
    keepalive.interval(5);

    keepalive.onPing.subscribe(
      () => {
        this.lastPing = new Date();
        this.oSessionService.cSessionService_setSessionExpiry();
      });
      this.AppComponent_ResetTimer();
  }

  AppComponent_ResetTimer() {
    this.AppComponent_CheckSessionExpiry();
    this.oCheckIdleState.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
    console.log("AppComponent_ResetTimer");
  }

  AppComponent_CheckSessionExpiry() {
    const oDate = new Date();
    const nSessionExpiryTime = this.oSessionService.cSessionService_getSessionExpiry();
    const nCurrentTime = oDate.getTime();

    if (nSessionExpiryTime !== null) {
      // compare the expiry time of the item with the current time
      if (nCurrentTime > nSessionExpiryTime) {
        let user=this.oSessionService.SessionService_GetUserDetails()
        if(user!=null)this.removeDeviceToken(user)
        localStorage.removeItem("ls" + environment.APP_NAME + "SessionExpiryTime");
        localStorage.clear();
        console.log("Session_CheckSessionExpiry: Current Session is expired. So, logging out");
        this.oRouter.navigateByUrl('/auth/login');
      }
    }
  }
  ngOnDestroy(): void {
    let user=this.oSessionService.SessionService_GetUserDetails()
    this.removeDeviceToken(user)
  }
  removeDeviceToken(incomingUser) {
    let body = {
      id:incomingUser.id
      }
      this.dataService.DeleteDeviceToken(body).subscribe({
        next:(result) => {
          if (result.status == "failed") {
            console.log(
              "cHeaderComponent_removeDeviceToken: ERROR ===>>>",
              result.message
            );
          } else {
            console.log("cHeaderComponent_removeDeviceToken: Response ===>>");
          }
        },
        error:(error) => {
          console.error(
            "cHeaderComponent_removeDeviceToken: ERROR ===>>>",
            error
          );
        }
    });
  }
  ngOnInit(): void {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.mapApiKey}&libraries=visualization`;
    script.defer = true;
    script.async = true;
   
       document.head.appendChild(script);
  }
}
