import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filterNotifications, Notifications } from 'src/app/models/notifications';
import { DataService } from 'src/app/services/data.service';
import { SessionService } from 'src/app/services/session.service';
interface SideNavToggle {
  collapsed: boolean;
  screenWidth: number;
}


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // @Input() toggleSidebar: any;
  @Output() onToggleSidenav: EventEmitter<SideNavToggle> = new EventEmitter();
  collapsed: boolean = true;
  screenWidth: number = 0;
  oUserdetails: any;
  bDisplayNotification: boolean = false;
  changePasswordForm: FormGroup;
  changeNameForm : FormGroup;
  fillSvg: boolean;
  bDisplaySettingsMenu: boolean = false
  fillNotificationSvg: boolean;
  fillSettingSvg: boolean;
  profileData: any;
  displayErrorBlock: boolean;
  resMessage: { message: any; responseType: any; color: any; };
  imageUrl: any;
  pictureUrl: string;
  notificationsList: Notifications[]=[];
  isSeenByAdminZero: boolean;
  filters : filterNotifications[] = [
    { name: 'All', value:'all'},
    { name: 'Order', value:'order'},
    { name: 'Incident', value:'incident'},
    { name: 'Fleet', value:'fleet'},
    { name: 'Maintenance', value:'maintenance'},
  ]
  selectedFilter: string = 'all';
  @ViewChild('scrollContainer', { static: false }) scrollContainerRef: ElementRef;
  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event): void {
    this.screenWidth = window.innerWidth
    if (this.screenWidth <= 768) {
      this.collapsed = false;
      this.onToggleSidenav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
  }
}
  constructor(private oRoute: Router, private sessionService: SessionService, private oModalService: NgbModal, private dataService: DataService) { 
  
  }

  ngOnInit(): void {
    this.oUserdetails = this.sessionService.SessionService_GetUserDetails();
    this.initializeChangePasswordForm();
    this.initializeChangeNameForm();
    this.getUserProfile();
    this.getNotifications(this.selectedFilter);

  }


  // --------------- Initialize Password Form ----------------
  initializeChangePasswordForm() {
    const oPasswordRejex = /^.{8,}$/;
    this.changePasswordForm = new FormGroup({
      userId: new FormControl([this.oUserdetails.userId]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(oPasswordRejex)]),
      rptPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
      oldPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(oPasswordRejex)]),
    },
      this.mustMatch
    )

  }


  // --------------- Initialize Name Form ----------------
  initializeChangeNameForm()
  {
    this.changeNameForm = new FormGroup({
      userId : new FormControl(this.oUserdetails.userId, [Validators.required]),
      firstname: new FormControl(this.oUserdetails.firstName, [Validators.required]),
      lastname: new FormControl(this.oUserdetails.lastName, [Validators.required])
    })
  }


  // --------------- Must Match Validator ----------------
  private mustMatch(AC: AbstractControl) {
    let password = AC.get('password').value;
    if (AC.get('rptPassword').touched || AC.get('rptPassword').dirty) {
      let verifyPassword = AC.get('rptPassword').value;
      if (password != verifyPassword) {
        AC.get('rptPassword').setErrors({ mustMatch: true });
      } else {
        return null;
      }
    }
  }


  // --------------- Get User Profile ----------------
  getUserProfile() {
    let body = {
      id: this.oUserdetails.id
    }
    this.dataService.GetUserProfile(body).subscribe({
      next: (result) => {
        if (result.status == 'success') {
         
          this.imageUrl = result.data.pictureUrl;
         let userDetails = this.sessionService.SessionService_GetUserDetails()
         userDetails.pictureUrl = this.imageUrl;
         this.sessionService.SessionService_SetUserDetails(userDetails)
        }


      },
      error: (error) => { },
      complete: () => { }
    })
  }


  // --------------- Change Profile Request ----------------
  changeProfileImage(event) {
    let file = event.target.files[0];

    let body = new FormData();
    body.append("file", file);
    body.append("folder", 'public/admins');
    body.append("functionName","UpdateProfilePicture"),
    body.append("makePublic", "false");
    this.dataService.ChangeProfileImage(body).subscribe({
      next: (result) => {
        if (result.status == 'failed') { }
        else {
          this.pictureUrl = result.folderName + "/" + result.fileName ;
          this.getUserProfile()
        }
      },
      error: (error) => { },
      complete: () => { }
    })

  }


  // --------------- Change Password Request ----------------
  changeUserPassword() {
    if(this.changePasswordForm.valid)
    {
      let body = this.oUserdetails
      body.oldPassword = this.changePasswordForm.controls['oldPassword'].value
      body.password = this.changePasswordForm.controls['password'].value
      body.rptPassword = this.changePasswordForm.controls['rptPassword'].value
      this.dataService.ChangePassword(body).subscribe({
        next: (result) => {
          if (result.failed) {
            this.showResponseMessage(result.message, 'failed', 'danger')
          }
          else {
            this.oModalService.dismissAll();
            this.changePasswordForm.reset()
            this.showResponseMessage('Password updated successfully', 'success', 'success')
          }
        },
        error: (error) => {
          this.showResponseMessage(error, 'failed', 'danger')
        },
        complete: () => { },
      })
    }
    else
    {
      this.changePasswordForm.markAllAsTouched()
    }
  }


  // --------------- Change Name Request ----------------
  changeName()
  {
    let body=this.oUserdetails
    body.pictureUrl = this.pictureUrl,
    body.firstName=this.changeNameForm.controls['firstname'].value
    body.lastName=this.changeNameForm.controls['lastname'].value
    body.fullName=body.firstName+" "+body.lastName
    this.dataService.EditUserProfile(body).subscribe({
    next: (result) => {
     if(result.failed)
     {
      this.showResponseMessage( result.message,'failed','danger');
     }
     else
     {
      this.oModalService.dismissAll();
      this.showResponseMessage('Profile updated successfully','success','success');
      this.oUserdetails = result.props.user;
      this.sessionService.SessionService_SetUserDetails(result.props.user);
      this.getUserProfile()
     }
    },
    error: (error) => {
      this.showResponseMessage( error.message,'failed','danger');
    },
    complete: () => { },
  })
}


  // --------------- Notifications Dropdown ----------------
  displayNotifications() {
    this.getNotifications(this.selectedFilter)
    setTimeout(() => {
      this.bDisplayNotification = !this.bDisplayNotification;
      this.fillNotificationSvg = !this.fillNotificationSvg;
    }, 100);
    
  }


  // --------------- Settings Dropdown ----------------
  displaySettingsDropdown() {
    setTimeout(() => {
      this.bDisplaySettingsMenu = true;
      this.fillSettingSvg = true;
    }, 100);

  }

  // --------------- Settings Dropdown ----------------
  getNotifications(incommingValue)
  {
    let body = {
      type:incommingValue,
      role:'admin'
    }
    this.dataService.GetNotifications(body).subscribe({
      next: (result) => {
        if (result.status == 'success') {
          this.notificationsList =[]
          result.data.data.forEach(element =>{
            this.notificationsList.push({assignedTo: element.assignedTo, createdAt: element.createdAt,generatedBy:element.generatedBy,
              id:element.id,isSeen:element.isSeenByAdmin,text:element.text,updatedAt:element.updatedAt,title:element.title,type:element.type})
          })
          this.isSeenByAdminZero = this.notificationsList.some(item => item.isSeen ===0 || item.isSeen == null);
        }
        else{}
      }
      ,
      error: (error) => { },
      complete: () => { }
    })
  }


  // --------------- Update notification ----------------
  updateNotification(id)
  {
    let body: { ids: string[], role?: string } = {
      ids: (id == null) ? this.notificationsList.map(item => item.id.toString()) : [id.toString()],
      role: 'admin'
    };
    this.dataService.UpdateNotification(body).subscribe({
      next: (result) => {
        if (result.status == 'success') {
          this.getNotifications(this.selectedFilter)
        }
        else{}
      }
      ,
      error: (error) => { },
      complete: () => { }
    })
  }


  // --------------- Update filter ----------------
  updateFilter(incommingValue)
  {
    if (this.scrollContainerRef && this.scrollContainerRef.nativeElement) {
      this.scrollContainerRef.nativeElement.scrollTop = 0;
    }
    this.selectedFilter = incommingValue
    this.getNotifications(this.selectedFilter)
  }


  // --------------- Outside Click Directive ----------------
  clickOutside(incommingValue) {
    if (incommingValue == 'settings') {
      this.bDisplaySettingsMenu = false
    }
    else {
      
      this.bDisplayNotification = false;
      this.selectedFilter = 'all'
      this.fillNotificationSvg = false;
    }
    this.fillSettingSvg = false
  }


  // --------------- Password Modal ----------------
  openChangePasswordModal(changePassword) {
    this.oModalService.open(changePassword, { centered: true, size: 'md' });
    this.initializeChangePasswordForm()
  }


  // --------------- Profile Modal ----------------
  openUpdateProfileModal(updateProfile) {
    this.oModalService.open(updateProfile, { centered: true, size: 'md' });
  }


  // --------------- Remove Device Token ----------------
  removeDeviceToken(incomingUser) {
    let body = {
      id: incomingUser
    }
    this.dataService.DeleteDeviceToken(body).subscribe({
      next: (result) => {
        if (result.status == "failed") {
          console.log(
            "cHeaderComponent_SaveDeviceToken: ERROR ===>>>",
            result.message
          );
        } else {
          console.log("cHeaderComponent_SaveDeviceToken: Response ===>>");
        }
      },
      error: (error) => {
        console.error(
          "cHeaderComponent_SaveDeviceToken: ERROR ===>>>",
          error
        );
      }
    });
  }


  // --------------- Toggle Collapse ----------------
  toggleCollapse() {
    this.collapsed = !this.collapsed;
    this.onToggleSidenav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth });
  }


  // --------------- Logout ----------------
  logout() {
    this.oRoute.navigateByUrl('/auth/login')
    this.removeDeviceToken(this.oUserdetails.id)
    setTimeout(() => { localStorage.clear(); }, 100);
  }


  // --------------- Dismiss Modal ----------------
  dismissModal() {
    this.oModalService.dismissAll();
    this.changePasswordForm.reset()
  }


  // --------------- Alerts ----------------
  showResponseMessage(sIncommingMessage, sIncommingResponseType, sIncommingColor) {
    this.displayErrorBlock = true
    this.resMessage =
    {
      message: sIncommingMessage,
      responseType: sIncommingResponseType,
      color: sIncommingColor
    };
    setTimeout(() => { this.displayErrorBlock = false; }, 3000);
  }


  // load error image
  loadAvatar()
  {
    this.imageUrl = '../../../../assets/images/users/profile-avatar.png'
  }
}