    <app-loader *ngIf="loader"></app-loader>
    <div *ngIf="!loader" class="card border-0 rounded-3 mb-4">
        <div class="card-body d-flex gap-2 p-3 justify-content-center">
            <div class="box-container" *ngIf="videos.length>0">
                <div  *ngFor="let video of videos; let i = index" class="box video-wrapper"  [class.expanded]="expandedClass == i+1">
                    <!-- <span class="time font-14">{{box.time}}</span> -->
                    <video #videoPlayer [src]="video" height="250" controls autoplay [muted]="true" controlsList="nodownload"></video>
                    <span class="pin-box">
                        <!-- <i (click)="toggleExpand(i+1)" class="fa-solid fa-thumbtack pin-icon text-white"></i> -->
                        <svg *ngIf="expandedClass !==i+1" width="20" height="20" (click)="toggleExpand(i+1)" viewBox="0 0 24 24" class="GfYBMd rEu3Tb"><path fill="white" d="M17,4v7l2,3v2h-6v5l-1,1l-1-1v-5H5v-2l2-3V4c0-1.1,0.9-2,2-2h6C16.11,2,17,2.89,17,4z M9,4v7.75L7.5,14h9L15,11.75V4H9z"></path></svg>
                        <svg *ngIf="expandedClass ==i+1" width="20" height="20" (click)="toggleExpand(i+1)" viewBox="0 0 24 24" class="GfYBMd rEu3Tb"><path fill="white" fill-rule="evenodd" clip-rule="evenodd" d="M2 3.22L3.42 1.81L21.8 20.2L20.39 21.61L14.78 16H13V21L12 22L11 21V16H5V14L7 11V8.22L2 3.22ZM9 10.22V11.75L7.5 14H12.78L9 10.22Z"></path><path fill="white" d="M19 14.5693L15 10.5671V4H8.43647L7.33397 2.8969C7.69315 2.35734 8.30687 2 9 2H15C16.11 2 17 2.89 17 4V11L19 14V14.5693Z"></path></svg>
                    </span>
                </div>
              </div>

              <div class="text-center" *ngIf="videos.length == 0">
                No videos footage found!
              </div>
        </div>
    </div>
