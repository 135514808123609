import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isEmptyObject'
})
export class IsEmptyObjectPipe implements PipeTransform {

  transform(value: any): boolean {
    if (value) {
      return Object.keys(value).length === 0;
    }
    return true;
  }

}
