import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from './loader/loader.component';
import { AlertsComponent } from './alerts/alerts.component';
import { VideoFeedComponent } from './video-feed/video-feed.component';
import { VideoPlaybackComponent } from './video-playback/video-playback.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SublevelMenuComponent } from './sidebar/sublevel-menu/sublevel-menu.component';
import { ClickOutsideDirective } from './click-outside.directive';
import { NoResultsComponent } from './no-results/no-results.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IsEmptyObjectPipe } from './pipes/is-empty-object.pipe';
import { LargeValueformatPipe } from './pipes/large-valueformat.pipe';
import { TimeUnitPipe } from './pipes/time-unit.pipe';


@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    LoaderComponent,
    AlertsComponent,
    VideoFeedComponent,
    VideoPlaybackComponent,
    SublevelMenuComponent,
    ClickOutsideDirective,
    NoResultsComponent,
    IsEmptyObjectPipe,
    LargeValueformatPipe,
    TimeUnitPipe
  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    BsDatepickerModule.forRoot(),
    FormsModule,
    ReactiveFormsModule


  ],
  exports: [HeaderComponent,SidebarComponent,LoaderComponent,AlertsComponent,VideoFeedComponent,
     VideoPlaybackComponent,IsEmptyObjectPipe,LargeValueformatPipe, TimeUnitPipe]
})
export class SharedModule { }
