import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(private http: HttpClient) { }

  GetUsersList(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/page/auth/vault', body);
  }
  CreateUser(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/newUser', body);
  }
  UploadUsers(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/authds/uploadUsers', body);
  }
  EditUser(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/editUser', body);
  }
  ResetPassword(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/resetPassword', body);
  }
  SuspendUser(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/toggleSuspendActive', body);
  }
  DeleteUser(id : any): Observable<any> {
    return this.http.delete<any>(environment.BaseURL + '/_service/um/deleteUser'+"?id="+ id);
  }
  AddNewTenant(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/save/auth/vault/Org', body);
  }

  GetDashboardData(): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/ds/function/shared/KALMGetDashboardData');
  }
  GetOrderDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetMockOrderDetails',body);
  }
  GetHeatmapsData(): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/ds/function/shared/GetMockHeatMap');
  }
  GetEventsDataByDate(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetCalendarEvents',body);
  }
  subscribeToTopic(body): Observable<any> {
    return this.http.post<any>('/projects/apps-demo-1/subscriptions/location-updates',body);
  }
  GetAllDrivers(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAllDrivers',body);
  }
  GetDriversDetail(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetDriverDetail',body);
  }
  GetVehiclesList(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAllVehicles',body);
  }
  GetVehicleDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetVehicleDetail',body);
  }
  GetMaintenanceHistory(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetMaintenanceHistory',body);
  }
  GetIncidentsHistory(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetIncidentHistory',body);
  }
  SaveDeviceToken(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SaveDeviceToken', body)
  }
  DeleteDeviceToken(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DeleteDeviceToken', body)
  }
  GetAllOrders(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetTotalOrders',body);
  }
  GetOrdersDetail(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetOrderDetail',body);
  }
  GetVideoFootages(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetVehicleFootage',body);
  }
  GetAllShippments(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAllShipments',body);
  }
  GetShipmentDetail(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetShipmentDetail',body);
  }
  GetAllCamerasListForVehicle(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAllCameras',body);
  }
  GetVehicleFootageFeedThoughCamera(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetVehicleFootage',body);
  }
  ChangeProfileImage(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/upload',body);
  }

  GetUserProfile(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetUserProfile',body);
  }
  ChangePassword(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/_service/um/changeMyPassword',body)
  }
  GetAllClients(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAllCustomers',body);
  }
  GetClientDetail(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetCustomerDetail',body);
  }
  GetAllInsurances(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/function/shared/GetAllInsurances',body)
  }
  GetInsuranceDetail(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/function/shared/GetInsuranceDetail',body)
  }
  GetDashboardTickets(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/function/shared/GetDashboardTickets',body)
  }
  GetDashboardDrivers(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/function/shared/GetDashboardDrivers',body)
  }
  GetDashboardTotalOrders(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL +'/ds/function/shared/GetDashboardOrders',body)
  }
  GetAllIncidents(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAllIncidents',body);
  }
  GetIncidentDetail(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetIncidentDetail',body);
  }
  GetAdminRouteSimulatorTrigger(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/RouteSubscriberTrigger',body);
  }
  CancelAdminRouteSimulatorTrigger(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/systask/cancelTasks',body);
  }
  LiveFleetMap(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/LiveFleetMap',body);
  }

  EditUserProfile(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/editUser', body);
  }
  GetAllMaintenanceOrders(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAllMaintenanceOrders', body);
  }
  DataService_EditUserProfile(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/editUser', body);
  }
  GetOrderHistory(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetOrderHistory',body);
  }
  GetPaymentHistory(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetPaymentHistory',body);
  }
  GetShippingHistory(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetShippingHistory',body);
  }
  GetVehicleHistory(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetVehicleHistory',body);
  }
  GetDriverHistory(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetDriverHistory',body);
  }
  GetMaintenanceDetail(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetMaintenanceDetail',body);
  }
  GetNotifications(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetNotifications',body);
  }
  UpdateNotification(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/UpdateNotification',body);
  }
  GetAllPayments(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAllPayments',body);
  }
  ResolveIncident(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/UpdateIncident',body);
  }
  RunFleetTrigger(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/RunFleetTrigger',body);
  }
  FleetTaskStatus(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/FleetTaskStatus',body);
  }
  GetTaskIds(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/page/SysTaskExec',body);
  }
  CancelTask(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/systask/cancelTasks',body);
  }
  CheckBusyStatus(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/systask/anyOfTasksBusy',body);
  }
}