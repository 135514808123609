import { Component, Input, OnInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'video-playback',
  templateUrl: './video-playback.component.html',
  styleUrls: ['./video-playback.component.css']
})
export class VideoPlaybackComponent implements OnInit {
  @Input() VehicleChannelDetails : any;
  @Input() vehicleId;
  oSelectedCamera : any;
  oSelectedDateAndTime : any;
  CreatedVideoLink : string;
  displayErrorBlock: boolean;
  resMessage: { message: any; responseType: any; color: any; };
  constructor(private dataSevice : DataService) {}
  ngOnInit(): void {}
  VideoPlayBackComponent_selectCameraForVideo(oIncomingSelectedCamera : any)
  {
    this.oSelectedCamera = oIncomingSelectedCamera;
  }
  VideoPlayBackComponent_selectDateAndTimeForVideo(date : Date)
  {
    const timestamp = new Date(date).getTime();
    this.oSelectedDateAndTime = timestamp;
  }
  VideoPlayBackComponent_GetVideo()
  {
    let body = {
      vehicleId : this.vehicleId,
      cameraId : this.oSelectedCamera.id,
      timeStamp : this.oSelectedDateAndTime/1000
    }
    this.dataSevice.GetVehicleFootageFeedThoughCamera(body).subscribe({
      next: (result) => {
        if (result.status == 'success') {
          this.CreatedVideoLink = result.data[0];
          if(result.data.length == 0)this.displayAlertMessage('No video found', 'success', 'success');
        }
        else {
          console.log("Error", result);
          this.displayAlertMessage(result.message, 'failed', 'danger');
        }
      },
      error: (result) => {
        console.log("Error", result);
        this.displayAlertMessage(result.message, 'failed', 'danger');
      },
      complete: () => { }
    })
  }
     // -------------------- ALERT MESSAGES --------------------
     displayAlertMessage(sIncommingMessage, sIncommingResponseType,sIncommingColor) {
      this.displayErrorBlock = true
      this.resMessage = 
      {
        message: sIncommingMessage,
        responseType : sIncommingResponseType,
        color :  sIncommingColor
      };
      setTimeout(() => { this.displayErrorBlock = false; }, 3000);
    }
}
