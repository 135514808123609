import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { cAuthGuard } from './guards/auth.guard';
import { cBlankComponent } from './layouts/blank/blank.component';
import { cFullComponent } from './layouts/full/full.component';
import { canActivateTeam } from './guards/auth.guard';

const routes: Routes = [
  { path:'', redirectTo:'auth/login',pathMatch:'full'},
  {
    path: '',
    component: cBlankComponent,
    children:
    [
      {
        path:'auth',
        loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
      },
      {
        path:'',
        redirectTo: "/app/dashboard",
        pathMatch: "full"
      },
    ]
  },
  {
    path: '', canActivate: [canActivateTeam],
    component: cFullComponent,
    children:
    [
      {
        path:'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path:'app',
        loadChildren: () => import('./modules/apps/apps.module').then(m => m.AppsModule)
      },
      {
        path:'usermanager',
        loadChildren: () => import('./modules/user-manager/usermanager.module').then(m => m.UsermanagerModule)
      },
      {
        path:'incidents',
        loadChildren: () => import('./modules/incidents/incidents.module').then(m => m.IncidentsModule)
      },
      {
        path:'drivers',
        loadChildren: () => import('./modules/drivers/drivers.module').then(m => m.DriversModule)
      },
      {
        path:'vehicles',
        loadChildren: () => import('./modules/vehicles/vehicles.module').then(m => m.VehiclesModule)
      },
      {
        path:'shipments',
        loadChildren: () => import('./modules/shipments/shipments.module').then(m => m.ShipmentsModule)
      },
      {
        path:'maintenance',
        loadChildren: () => import('./modules/maintenance/maintenance.module').then(m => m.MaintenanceModule)
      },
      {
        path:'insurance',
        loadChildren: () => import('./modules/insurance/insurance.module').then(m => m.InsuranceModule)
      },
      {
        path:'payments',
        loadChildren: () => import('./modules/payments/payments.module').then(m => m.PaymentsModule)
      },
      {
        path:'clients',
        loadChildren: () => import('./modules/clients/clients.module').then(m => m.ClientsModule)
      },
      {
        path:'orders',
        loadChildren: () => import('./modules/orders/orders.module').then(m => m.OrdersModule)
      },
    ]
  },
  {
    path: "**",
    redirectTo: "auth/not-found",
    pathMatch: "full"
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
