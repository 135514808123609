import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeUnit'
})
export class TimeUnitPipe implements PipeTransform {

  transform(policyPeriod: number): string {
    if (policyPeriod >= 365) {
      const years = Math.floor(policyPeriod / 365);
      const remainingDays = policyPeriod % 365;
      const yearString = years > 1 ? `${years} years` : `${years} year`;
  
      if (remainingDays > 0) {
        const months = Math.floor(remainingDays / 30);
        const dayString = remainingDays > 1 ? `${remainingDays} days` : `${remainingDays} day`;
        const monthString = months > 1 ? `${months} months` : `${months} month`;
        
        if (months > 0) {
          return `${yearString}, ${monthString}, and ${dayString}`;
        } else {
          return `${yearString} and ${dayString}`;
        }
      } else {
        return yearString;
      }
    } else {
      const months = Math.floor(policyPeriod / 30);
      const remainingDays = policyPeriod % 30;
      const monthString = months > 1 ? `${months} months` : `${months} month`;
  
      if (remainingDays > 0) {
        return `${monthString} and ${remainingDays} days`;
      } else {
        return monthString;
      }
    }}

}
