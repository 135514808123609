import { Injectable } from '@angular/core';
import { HttpRequest,HttpHandler,HttpEvent,HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import { Observable,  throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { SessionService } from 'src/app/services/session.service';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

  constructor(private oSessionService:SessionService) {}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> { 
    let oHeaders = 
    {
      'Accept':'*/*',
      'x-app-name':'main',
      'x-org-name':'cloud',
      'content-type':'application/json',
      'Authorization':'Bearer ' + this.oSessionService.SessionService_GetAccessToken()
    }
    if((request.url.includes('ajaxLogin')) ) delete oHeaders['Authorization'];
    if(request.url.includes('/upload')) delete oHeaders['content-type'];


    let oRequestWithHeaders  = request.clone({ setHeaders: oHeaders });
    return next.handle(oRequestWithHeaders)
    .pipe(retry(0), catchError((error: HttpErrorResponse)=> {
      let errorMessage: any;
      if(error.error instanceof ErrorEvent){
        //client side error
        errorMessage = `Error: ${error.error.message}`;
      }
      else
      {
        //server side error
       
        errorMessage = error.error.message
      }
      return throwError(() => new Error(errorMessage))
    }))
  }
}
