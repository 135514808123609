import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'largeValueformat'
})
export class LargeValueformatPipe implements PipeTransform {

  transform(value: number): string {
    if (value >= 1000) {
      return `${(value / 1000).toFixed(2)}k`;
    } else if(value >=1000000) {
      return `${(value / 1000000).toFixed(2)}m`;
    }
    else
    {
      return value.toString();
    }
  }

}
