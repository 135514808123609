<ng-container *ngIf="!bOldBrowser">
    <router-outlet></router-outlet>
</ng-container>
<ng-container *ngIf="bOldBrowser">
     <div class="center">
         <div class="card shadow">
            <div class="card-header ">
                <h1 class="mb-0 text-danger d-flex align-items-center"> <i class="fa fa-exclamation-triangle mr-2" aria-hidden="true"></i> Incompatible Browser</h1>
            </div>
            <div class="card-body">
                <p class="text-center font-24 font-600">
                    The browser <span class="text-info"> {{oBroswerNameVersion.browserName}} {{oBroswerNameVersion.browserVersion}}</span> is not supported to run this application. <br>
                    Please upgrade to the latest version of <span class="text-info">{{oBroswerNameVersion.browserName}}.</span><br>
                    Thanks
                </p>
            </div>
         </div>
     </div>
</ng-container>
