<app-alerts *ngIf="displayErrorBlock" [resMessage]="resMessage"></app-alerts>
    <div class="card border-0 rounded-3 mb-4">
        <div class="card-body p-3" >
            <div class="row">
                <div class="col-lg-12 custom-col-lg-media">
                    <div class="row">
                        <div class="col-md-6 col-lg-5 mb-4">
                            <select class="form-control form-select" (change)="VideoPlayBackComponent_selectCameraForVideo($event)">
                                <option value="" disabled selected>Select Camera</option>
                                <option [value]="item" *ngFor="let item of VehicleChannelDetails"> {{'Camera'+ item.id}} ({{item.position}})</option>
                            </select>
                        </div>
                        <div class="col-md-6 col-lg-5 mb-4">
                                  <div class="text-center">
                                    <input type="text"
                                    placeholder="Choose date & time"
                                    class="form-control"
                                    (bsValueChange)="VideoPlayBackComponent_selectDateAndTimeForVideo($event)"
                                    [bsConfig]="{withTimepicker: true,keepDatepickerOpened: true, rangeInputFormat : 'MMMM Do YYYY, h:mm:ss a', dateInputFormat: 'MMMM Do YYYY, h:mm:ss a'}"
                                    bsDatepicker>
                                  </div>
                        </div>
                        <div class="col-md-6 col-lg-2">
                            <button class="btn btn-primary w-100" (click)="VideoPlayBackComponent_GetVideo()" [disabled]="!oSelectedCamera || !oSelectedDateAndTime">Get Video</button>
                        </div>
                    </div>
                </div>
                <div *ngIf="CreatedVideoLink" class="col-lg-12 custom-col-lg-media video-container">
                    <video [src]="CreatedVideoLink" controls autoplay controlsList="nodownload" ></video>
                </div>
                <div *ngIf="!CreatedVideoLink" class="card-body d-flex justify-content-center ">
                    <div class="font-14 fw-500">
                        No Video Found!
                    </div>
                </div>
            </div>

        </div>
    </div>
