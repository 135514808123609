import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }

  //set session expiry
  cSessionService_setSessionExpiry() {
    const nSessionExpiryTime: number = new Date().getTime() + 1800000;
    localStorage.setItem("ls" + environment.APP_NAME + "SessionExpiryTime", JSON.stringify(nSessionExpiryTime));
  }
  //get session expiry
  cSessionService_getSessionExpiry() {
    return JSON.parse(localStorage.getItem("ls" + environment.APP_NAME + "SessionExpiryTime"));
  }
  // set access token
  SessionService_SetAccessToken(oData: any){
    localStorage.setItem(environment.AccessToken,JSON.stringify(oData))
  }
  // get access token
  SessionService_GetAccessToken(){
    return JSON.parse(localStorage.getItem(environment.AccessToken))
  }
  // set user details
  SessionService_SetUserDetails(oData: any){
    localStorage.setItem('lsUserDetails',JSON.stringify(oData))
  }
  // get user details
  SessionService_GetUserDetails()
  {
    return JSON.parse(localStorage.getItem('lsUserDetails'));
  }
  // set firebase token
  SessionService_SetFireBaseToken(oData: any){
    localStorage.setItem('firebaseToken',JSON.stringify(oData))
  }
  // get access token
  SessionService_GetFireBaseToken(){
    return JSON.parse(localStorage.getItem('firebaseToken'))
  }
  SessionService_SetTaskDetails(oData: any)
  {
    localStorage.setItem('TaskDetails',JSON.stringify(oData))
  }
  SessionService_GetTaskDetails()
  {
    return JSON.parse(localStorage.getItem('TaskDetails'))
  }

}
