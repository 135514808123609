import { INavbarData } from "./helper";

export const navbarData :INavbarData[]= [
    {
        routerLink: '/dashboard',
        icon:'home',
        label: 'Home',
        
    },
    {
        routerLink: 'app/livefleet',
        icon:'fleet-map',
        label: 'Live Fleet Map'
    },
    {
        routerLink: '/incidents',
        icon:'incident',
        label: 'Incidents'
    },
    {
        routerLink: '/drivers',
        icon:'drivers',
        label: 'Drivers'
    },
    {
        routerLink: '/vehicles',
        icon:'vehicle',
        label: 'Vehicles'
    },
    {
        routerLink: '/app/orders',
        icon:'orders',
        label: 'Orders',
        items:[
            {
                routerLink:'/orders',
                label:'Orders',
                icon:'orders',
            },
            {
                routerLink:'shipments',
                icon:'shipment',
                label:'Shipments'
            },
            {
                routerLink: '/payments',
                icon:'financial',
                label: 'Payments'
            },
            {
                routerLink: '/clients',
                icon:'clients',
                label: 'Clients'
            },
        ]
    },
  
    {
        routerLink: '',
        icon:'events',
        label: 'Events',
        items:[
            {
                routerLink:'/maintenance',
                icon:'settings',
                label:'Maintenance'
            },
            {
                routerLink: 'app/calander',
                icon:'calander',
                label: 'Calendar'
            },
            
        ]
    },
    // {
    //     routerLink: '/payments',
    //     icon:'financial',
    //     label: 'Payments'
    // },
    {
        routerLink: '/insurance',
        icon:'insurance',
        label: 'Insurance'
    },
    // {
    //     routerLink: 'usermanager',
    //     icon:'clients',
    //     label: 'Admin'
    // },
]